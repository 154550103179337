import { useEffect, useState } from 'react';
import ApiService from "../services/api.service";
import HelperService from "../services/helper.service";
import ProductList from './startpage/ProductList';
import ProductCategories from './startpage/ProductCategories';

import { useSelector, useDispatch } from 'react-redux';
import * as CartStore from '../store/cart/actions';
import * as GlobalStore from '../store/global/actions';
import * as StartPageStore from '../store/startpage/actions';
import * as ProductPageStore from '../store/productpage/actions';
import { Link } from 'react-router-dom';

const StartPage = () => {

    const dispatch = useDispatch();

    const showcategories = useSelector(state => state.startpage.showcategories);
    const categorystate = useSelector(state => state.startpage.categorystate);
    const activecatname = useSelector(state => state.startpage.activecategoryname);

    const useshoppingcart = useSelector(state => state.global.useshoppingcart);
    const shoppingcartproducts = useSelector(state => state.shoppingcart.shoppingcartproducts);

    const [error, setError] = useState(null);
    if (error) throw error;

    let categoryView;
    let categoryHeadline;

    if (showcategories) {
        categoryHeadline =
            categorystate === 'visible' ?
                <div className="col-lg-9 col-lg-offset-3 col-md-9 col-md-offset-3 col-sm-12 col-xs-12">
                    <h2 className="t-center categoryheadline">{activecatname}</h2>
                    {useshoppingcart ? <Link to="?path=warenkorb" className='carticon'><span>{HelperService.getCartProductCount(shoppingcartproducts)}</span></Link> : ''}
                </div> :
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h2 className="t-center categoryheadline">{activecatname}</h2>
                    {useshoppingcart ? <Link to="?path=warenkorb" className='carticon'><span>{HelperService.getCartProductCount(shoppingcartproducts)}</span></Link> : ''}
                </div>


        categoryView =
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <ProductCategories />
            </div>
    }

    useEffect(() => {

        dispatch(StartPageStore.resetData());
        dispatch(ProductPageStore.resetData());
        dispatch(CartStore.resetData());

        const queryParameters = new URLSearchParams(window.location.search)
        const predefinedcode = queryParameters.get("predefinedcode");
        if (predefinedcode) {
            dispatch(GlobalStore.setPredefinedCode(predefinedcode));
        }

        const API_URL = global.config.express.url + '/categoriesandproducts';
        const API_DATA = {
            'shop-url': global.config.apidata.shopurl,
            'categories': global.config.categories,
            'backendshop': global.config.backendshop,
            'exchange': global.config.exchange
        }
        ApiService.getData(API_URL, API_DATA).then((response) => {
            if (typeof (response.apierror) !== 'undefined') {
                setError(response.apierror);
            }
            else {
                dispatch(GlobalStore.setSpeech(response.speech));
                dispatch(GlobalStore.setUseShoppingCart(response['shopping-cart']));
                dispatch(StartPageStore.setCategories(response.categories));
                dispatch(StartPageStore.setProducts(response.products));
                if (response.categories.length > 2) {
                    dispatch(StartPageStore.setShowCategories(true));
                }
            }
        }).catch((error) => {
            setError(error);
        });
    }, [dispatch]);

    return (
        <div className="container nopadding">
            <div className="row">
                {categoryHeadline}
                {categoryView}
                <ProductList />
            </div>
        </div>
    );
}

export default StartPage;
